.more-games {
  background-color: #5F0848;
}

.game-lobby-container {
  padding: 10px;
  display: flex;
  margin: auto;
  margin-top: 20px
}

.game-lobby-container > img {
  margin-right: 5px;
  margin-top: -2px;
}

.mini-game-container {
  padding: 10px;
  height: 90vh;
}

.mini-game-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}